import gql from 'graphql-tag'

/**
 * Mutation to delete a product for the specified productId
 */
export const DELETE_PRODUCT = gql`
  mutation deleteProduct($productId: ID!) {
    deleteProduct(productId: $productId) {
      isDeleteSuccess
    }
  }
`
/**
 * Mutation to create product
 */
export const CREATE_PRODUCT = gql`
  mutation createProduct($product: ProductInput!) {
    createProduct(product: $product) {
      productId
      variants {
        variantId
      }
    }
  }
`
/**
 * Mutation to update product
 */
export const UPDATE_PRODUCT = gql`
  mutation updateProduct($product: ProductInput!, $productId: ID!) {
    updateProduct(product: $product, productId: $productId) {
      productId
      variants {
        variantId
      }
    }
  }
`
/**
 * Mutation to update Variant details
 */
export const UPDATE_VARIANT = gql`
  mutation updateVariant(
    $productId: ID!
    $variantId: ID!
    $variant: VariantInput!
  ) {
    updateVariant(
      productId: $productId
      variantId: $variantId
      variant: $variant
    ) {
      chargeTax
      disableAutomaticDiscount
      isOversellable
      option1
      optionLabels
      option2
      productId
      quantity
      sku
      title
      trackQuantity
      variantId
      productId
      variantType
      imageUrl
    }
  }
`

/**
 * Mutation to create Variant
 */
export const CREATE_VARIANT = gql`
  mutation createVariant($productId: ID!, $variant: VariantInput!) {
    createVariant(productId: $productId, variant: $variant) {
      chargeTax
      disableAutomaticDiscount
      isOversellable
      option1
      optionLabels
      option2
      productId
      variantId
      quantity
      sku
      title
      trackQuantity
      variantId
      variantType
      imageUrl
    }
  }
`

/**
 * Mutation to delete Variant
 */
export const DELETE_VARIANT = gql`
  mutation deleteVariant($productId: ID!, $variantId: ID!) {
    deleteVariant(productId: $productId, variantId: $variantId) {
      variantId
    }
  }
`
