import gql from 'graphql-tag'

/**
 * Query to get a market for the specified marketId
 */
export const GET_CURRENCY_PAIR = gql`
  query getCurrencyPair($target: CurrencyEnum!) {
    getCurrencyPair(target: $target) {
      pair {
        baseCurrency
        targetCurrency
        rate
        createdAt
        updatedAt
      }
    }
  }
`
