import apolloClient from '@/services/apollo'
import { GET_CURRENCY_PAIR } from '@/graphql/query/currencies'
import {
  CurrencyEnum,
  GetCurrencyPairResponse,
  Maybe,
  Price,
  QueryGetCurrencyPairArgs,
} from '../../../shared/types/types'

export const getDecimalPlaces = (currency: string): number => {
  const format = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  })
  const resolvedOptions = format.resolvedOptions()
  return resolvedOptions.maximumFractionDigits || 0
}

/**
 * Get currency
 *
 * @param target CurrencyEnum
 */
export const getCurrencyPair = async (
  target: CurrencyEnum
): Promise<Maybe<GetCurrencyPairResponse>> => {
  try {
    const { data } = await apolloClient.query<
      GetCurrencyPairResponse,
      QueryGetCurrencyPairArgs
    >({
      query: GET_CURRENCY_PAIR,
      variables: {
        target,
      },
      /* Caching by apolloClient is using 'id' for creating unique reference which is causing issues in fulfillments array,
      so temporarily we are disabling cache for this query. Need to work on the right fix: https://www.apollographql.com/docs/react/caching/cache-configuration/ */
      fetchPolicy: 'no-cache',
    })

    // @ts-ignore
    return data.getCurrencyPair || null
  } catch (e) {
    const data = JSON.parse(
      e instanceof Error ? e.message : 'Something went wrong'
    )
    console.log('🚀 ~ file: currencies.ts:47 ~ data', data)
    throw e
  }
}

export const shiftDecimalPlaces = (price: number, currency: string): number => {
  const decimalPlaces = getDecimalPlaces(currency)
  const diff = decimalPlaces - 2
  return price * Math.pow(10, diff)
}

export const exchangePrice = (
  priceAmount: number,
  currency: CurrencyEnum,
  exchangeRate: number,
  direction: 'to' | 'from' = 'to'
): Price | null => {
  // console.log('🚀 ~ file: currencies.ts:64 ~ price', priceAmount)
  // console.log('🚀 ~ file: currencies.ts:64 ~ currency', currency)
  // console.log('🚀 ~ file: currencies.ts:64 ~ exchangeRate', exchangeRate)

  if (isNaN(priceAmount) || !currency || !exchangeRate) {
    return null
  }
  // console.log('asdfasss')

  // Convert the price to the target currency by multiplying it with the exchange rate
  let amount = 0
  if (direction === 'to') {
    amount = Math.round(
      shiftDecimalPlaces(priceAmount, currency) * exchangeRate
    )
  } else {
    amount = Math.round(
      shiftDecimalPlaces(priceAmount, currency) / exchangeRate
    )
  }

  // console.log('🚀 ~ file: currencies.ts:76 ~ amount', amount)

  // Calculate the number of digits in the converted amount
  const numberOfDigits = amount.toString().length
  // console.log('🚀 ~ file: currencies.ts:80 ~ numberOfDigits', numberOfDigits)

  // Calculate the exponent, which is half of the number of digits or 1, whichever is larger
  const exponent = Math.max(Math.floor(numberOfDigits / 2), 1)
  // console.log('🚀 ~ file: currencies.ts:84 ~ exponent', exponent)

  // Calculate the ratio, which is 10 raised to the power of the exponent
  const ratio = Math.pow(10, exponent)
  // console.log('🚀 ~ file: currencies.ts:88 ~ ratio', ratio)

  // Round the converted amount to the nearest multiple of the ratio
  const roundedAmount = Math.round(amount / ratio) * ratio
  // console.log('🚀 ~ file: currencies.ts:92 ~ roundedAmount', roundedAmount)

  // Return the converted and rounded amount in the target currency
  return {
    amount: roundedAmount,
    currency,
  }
}
