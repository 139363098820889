import gql from 'graphql-tag'

/**
 * Query to get a product for the specified productId
 */
export const GET_PRODUCT = gql`
  query getProduct($productId: ID!) {
    getProduct(productId: $productId) {
      createdAt
      imageUrl
      productId
      optionLabels
      title
      description
      updatedAt
      variants {
        chargeTax
        disableAutomaticDiscount
        isOversellable
        option1
        option2
        option3
        optionLabels
        price {
          amount
          currency
        }
        discountedPrice {
          amount
          currency
        }
        internalPrice {
          amount
          currency
        }
        upsellPrice {
          amount
          currency
        }
        discountedUpsellPrice {
          amount
          currency
        }
        internalUpsellPrice {
          amount
          currency
        }
        packagePrice {
          amount
          currency
        }
        discountedPackagePrice {
          amount
          currency
        }
        internalPackagePrice {
          amount
          currency
        }
        comparePrice {
          amount
          currency
        }
        productId
        quantity
        sku
        title
        trackQuantity
        variantId
        variantType
        index
        imageUrl
      }
    }
  }
`
/**
 * Query to get a variant for the specified productId and variantId
 */
export const GET_VARIANT = gql`
  query getVariant($productId: ID!, $variantId: ID!) {
    getVariant(productId: $productId, variantId: $variantId) {
      chargeTax
      disableAutomaticDiscount
      createdAt
      option1
      option2
      option3
      optionLabels
      price {
        amount
        currency
      }
      discountedPrice {
        amount
        currency
      }
      internalPrice {
        amount
        currency
      }
      upsellPrice {
        amount
        currency
      }
      discountedUpsellPrice {
        amount
        currency
      }
      internalUpsellPrice {
        amount
        currency
      }
      packagePrice {
        amount
        currency
      }
      discountedPackagePrice {
        amount
        currency
      }
      internalPackagePrice {
        amount
        currency
      }
      comparePrice {
        amount
        currency
      }
      isOversellable
      productId
      quantity
      sku
      title
      trackQuantity
      updatedAt
      variantId
      variantType
      index
      imageUrl
    }
  }
`
