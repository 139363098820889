// @ts-ignore
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSJSON: any;
};

export type Query = {
  __typename?: 'Query';
  getCurrencyPair?: Maybe<GetCurrencyPairResponse>;
  getCustomerById?: Maybe<Customer>;
  getCustomerOrdersCount: OrdersCount;
  getDiscount?: Maybe<Discount>;
  getDiscountCurrencies: DiscountCurrenciesResponse;
  getFulfillmentGroups?: Maybe<OrderFulfillmentGroup>;
  getMarket?: Maybe<Market>;
  getOrder?: Maybe<Order>;
  getProduct?: Maybe<Product>;
  getShipping?: Maybe<ShippingProfile>;
  getShippingCurrencies: ShippingCurrenciesResponse;
  getTax?: Maybe<Tax>;
  getTaxCurrencies: TaxCurrenciesResponse;
  getTaxes: GetTaxesResponse;
  getTaxesForProductVariant: GetTaxesForProductVariantResponse;
  getVariant?: Maybe<Variant>;
};


export type QueryGetCurrencyPairArgs = {
  target: CurrencyEnum;
};


export type QueryGetCustomerByIdArgs = {
  customerId: Scalars['ID'];
};


export type QueryGetCustomerOrdersCountArgs = {
  customerId: Scalars['ID'];
};


export type QueryGetDiscountArgs = {
  discountId: Scalars['ID'];
};


export type QueryGetDiscountCurrenciesArgs = {
  discountId: Scalars['ID'];
};


export type QueryGetMarketArgs = {
  id: Scalars['ID'];
};


export type QueryGetOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryGetProductArgs = {
  productId: Scalars['ID'];
};


export type QueryGetTaxArgs = {
  taxId: Scalars['String'];
};


export type QueryGetTaxCurrenciesArgs = {
  taxId: Scalars['ID'];
};


export type QueryGetTaxesArgs = {
  collectionType?: InputMaybe<TaxCollectionEnum>;
};


export type QueryGetTaxesForProductVariantArgs = {
  productId: Scalars['ID'];
  variantId: Scalars['ID'];
};


export type QueryGetVariantArgs = {
  productId: Scalars['ID'];
  variantId: Scalars['ID'];
};

export enum CurrencyEnum {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Krw = 'KRW',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sle = 'SLE',
  Sos = 'SOS',
  Srd = 'SRD',
  Std = 'STD',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xcd = 'XCD',
  Xof = 'XOF',
  Xpf = 'XPF',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW'
}

export type GetCurrencyPairResponse = {
  __typename?: 'GetCurrencyPairResponse';
  pair?: Maybe<CurrencyPair>;
};

export type CurrencyPair = {
  __typename?: 'CurrencyPair';
  baseCurrency: CurrencyEnum;
  createdAt: Scalars['String'];
  rate: Scalars['Float'];
  targetCurrency: CurrencyEnum;
  updatedAt: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  analytics?: Maybe<Analytics>;
  billingAddress?: Maybe<Address>;
  createdAt?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastOrder?: Maybe<LastOrder>;
  marketingOptInLevel?: Maybe<MarketingLevelEnum>;
  note?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Address>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type Analytics = {
  __typename?: 'Analytics';
  averageOrderValue?: Maybe<Price>;
  ordersCount?: Maybe<Scalars['Int']>;
  totalSpent?: Maybe<Price>;
};

export type Price = {
  __typename?: 'Price';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyEnum>;
};

export type Address = {
  __typename?: 'Address';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  divisionCode?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type LastOrder = {
  __typename?: 'LastOrder';
  orderConfirmedAt?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
};

export enum MarketingLevelEnum {
  ConfirmedOptIn = 'ConfirmedOptIn',
  SingleOptInt = 'SingleOptInt',
  Unknown = 'Unknown'
}

export type OrdersCount = {
  __typename?: 'OrdersCount';
  ordersCount: Scalars['Int'];
};

export type Discount = {
  __typename?: 'Discount';
  currency?: Maybe<CurrencyEnum>;
  id: Scalars['ID'];
  name: Scalars['String'];
  rules: DiscountRules;
  status: DiscountStatusType;
  summary?: Maybe<Scalars['String']>;
  type: DiscountType;
  usage?: Maybe<Scalars['Int']>;
};

export type DiscountRules = {
  __typename?: 'DiscountRules';
  endsAt?: Maybe<Scalars['String']>;
  entitledCountryCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  entitledProductVariantIds?: Maybe<Array<Maybe<DiscountProductVariantId>>>;
  prerequisiteCustomerIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  prerequisiteMinPurchaseAmount?: Maybe<Price>;
  prerequisiteMinQuantity?: Maybe<Scalars['Int']>;
  prerequisiteProductVariantIds?: Maybe<Array<Maybe<DiscountProductVariantId>>>;
  prerequisiteToEntitlement?: Maybe<DiscountPrerequisiteToEntitlement>;
  startsAt: Scalars['String'];
  type?: Maybe<DiscountRuleType>;
  usageLimit?: Maybe<Scalars['Int']>;
  usageOncePerUser?: Maybe<Scalars['Boolean']>;
  value?: Maybe<DiscountValue>;
};

export type DiscountProductVariantId = {
  __typename?: 'DiscountProductVariantId';
  productId: Scalars['String'];
  variantId: Scalars['String'];
};

export type DiscountPrerequisiteToEntitlement = {
  __typename?: 'DiscountPrerequisiteToEntitlement';
  entitledQuantity?: Maybe<Scalars['Int']>;
  maxNumberOfUses?: Maybe<Scalars['Int']>;
  prerequisitePurchaseAmount?: Maybe<Price>;
  prerequisiteQuantity?: Maybe<Scalars['Int']>;
};

export enum DiscountRuleType {
  BuyXgetY = 'buyXgetY',
  FixedAmount = 'fixedAmount',
  FreeShipping = 'freeShipping',
  Percentage = 'percentage'
}

export type DiscountValue = DiscountPriceValue | DiscountScalarValue;

export type DiscountPriceValue = {
  __typename?: 'DiscountPriceValue';
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<CurrencyEnum>;
  type?: Maybe<DiscountValueType>;
};

export enum DiscountValueType {
  Price = 'price',
  Scalar = 'scalar'
}

export type DiscountScalarValue = {
  __typename?: 'DiscountScalarValue';
  type?: Maybe<DiscountValueType>;
  value: Scalars['Int'];
};

export enum DiscountStatusType {
  Active = 'active',
  Expired = 'expired',
  Scheduled = 'scheduled'
}

export enum DiscountType {
  Automatic = 'automatic',
  Code = 'code'
}

export type DiscountCurrenciesResponse = {
  __typename?: 'DiscountCurrenciesResponse';
  currencies: Array<Discount>;
};

export type OrderFulfillmentGroup = {
  __typename?: 'OrderFulfillmentGroup';
  fulfillmentGroupId: Scalars['ID'];
  fulfillmentStatus: FulfillmentStatusEnum;
  imageUrl?: Maybe<Scalars['String']>;
  productIds: Array<Scalars['ID']>;
  title: Scalars['String'];
  userSub: Scalars['ID'];
};

export enum FulfillmentStatusEnum {
  Fulfilled = 'fulfilled',
  Partial = 'partial',
  Unfulfilled = 'unfulfilled'
}

export type Market = {
  __typename?: 'Market';
  countries: Array<Country>;
  currency: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  canceledAt?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  charge?: Maybe<Charge>;
  chargebackFees?: Maybe<Array<Maybe<Price>>>;
  createdAt?: Maybe<Scalars['String']>;
  customAttributes?: Maybe<Scalars['AWSJSON']>;
  customer?: Maybe<Customer>;
  discount?: Maybe<Discount>;
  dispute?: Maybe<Dispute>;
  exchangeRate?: Maybe<ExchangeRate>;
  fee?: Maybe<Price>;
  finalSubTotalPrice?: Maybe<Price>;
  fraudDetection?: Maybe<FraudDetection>;
  fulfillments?: Maybe<Array<Maybe<Fulfillment>>>;
  fulfillmentStatus?: Maybe<FulfillmentStatusEnum>;
  hasActivityComments?: Maybe<Scalars['Boolean']>;
  lineItemQuantities?: Maybe<Scalars['AWSJSON']>;
  lineItems?: Maybe<Array<Maybe<LineItem>>>;
  lineItemsRefund?: Maybe<Price>;
  netPayment?: Maybe<Price>;
  note?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  originalSubTotalPrice?: Maybe<Price>;
  paymentStatus?: Maybe<PaymentStatusEnum>;
  refunds?: Maybe<Array<Maybe<Refund>>>;
  requiresShipping?: Maybe<Scalars['Boolean']>;
  shippingMethod?: Maybe<ShippingMethod>;
  shippingPrice?: Maybe<Price>;
  shippingRefund?: Maybe<Price>;
  status?: Maybe<OrderStatusEnum>;
  taxes?: Maybe<Array<Maybe<Tax>>>;
  taxPrice?: Maybe<Price>;
  totalChargebackFee?: Maybe<Price>;
  totalChargebacks?: Maybe<Price>;
  totalDiscountPrice?: Maybe<Price>;
  totalPrice?: Maybe<Price>;
  totalRefund?: Maybe<Price>;
  updatedAt?: Maybe<Scalars['String']>;
  usdPrices?: Maybe<Prices>;
};

export type Charge = {
  __typename?: 'Charge';
  amount?: Maybe<Price>;
  amountRefunded?: Maybe<Price>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDisputed?: Maybe<Scalars['Boolean']>;
  isLivemode?: Maybe<Scalars['Boolean']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  paymentMethod?: Maybe<PaymentMethod>;
  receiptNumber?: Maybe<Scalars['String']>;
  risk?: Maybe<Risk>;
  service?: Maybe<PaymentServiceEnum>;
  status?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  card: Card;
  type: Scalars['String'];
};

export type Card = {
  __typename?: 'Card';
  country?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  last4?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
};

export type Risk = {
  __typename?: 'Risk';
  level?: Maybe<RiskLevelEnum>;
  message?: Maybe<Scalars['String']>;
  reason?: Maybe<RiskReasonEnum>;
  score?: Maybe<Scalars['Int']>;
  status?: Maybe<RiskStatusEnum>;
  type?: Maybe<RiskTypeEnum>;
};

export enum RiskLevelEnum {
  Elevated = 'elevated',
  Highest = 'highest',
  Normal = 'normal',
  NotAssessed = 'not_assessed',
  Unknown = 'unknown'
}

export enum RiskReasonEnum {
  ElevatedRiskLevel = 'elevated_risk_level',
  HighestRiskLevel = 'highest_risk_level'
}

export enum RiskStatusEnum {
  ApprovedByNetwork = 'approved_by_network',
  DeclinedByNetwork = 'declined_by_network',
  NotSentToNetwork = 'not_sent_to_network',
  ReversedAfterApproval = 'reversed_after_approval'
}

export enum RiskTypeEnum {
  Authorized = 'authorized',
  Blocked = 'blocked',
  Invalid = 'invalid',
  IssuerDeclined = 'issuer_declined',
  ManualReview = 'manual_review'
}

export enum PaymentServiceEnum {
  Klarna = 'klarna',
  Stripe = 'stripe'
}

export type Dispute = {
  __typename?: 'Dispute';
  amount?: Maybe<Scalars['Int']>;
  chargeId?: Maybe<Scalars['String']>;
  currency?: Maybe<CurrencyEnum>;
  evidenceDetails?: Maybe<DisputeEvidenceDetails>;
  id?: Maybe<Scalars['String']>;
  isChargeRefundable?: Maybe<Scalars['Boolean']>;
  status?: Maybe<DisputeStatusEnum>;
};

export type DisputeEvidenceDetails = {
  __typename?: 'DisputeEvidenceDetails';
  dueBy?: Maybe<Scalars['Int']>;
  hasEvidence?: Maybe<Scalars['Boolean']>;
  pastDue?: Maybe<Scalars['Boolean']>;
  submissionCount?: Maybe<Scalars['Int']>;
};

export enum DisputeStatusEnum {
  ChargeRefunded = 'charge_refunded',
  Lost = 'lost',
  NeedsResponse = 'needs_response',
  UnderReview = 'under_review',
  WarningClosed = 'warning_closed',
  WarningNeedsResponse = 'warning_needs_response',
  WarningUnderReview = 'warning_under_review',
  Won = 'won'
}

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  base?: Maybe<CurrencyEnum>;
  rate?: Maybe<Scalars['Float']>;
  target?: Maybe<CurrencyEnum>;
};

export type FraudDetection = {
  __typename?: 'FraudDetection';
  chargeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  createdFraudAt?: Maybe<Scalars['String']>;
  fraudType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type Fulfillment = {
  __typename?: 'Fulfillment';
  id?: Maybe<Scalars['String']>;
  lineItems?: Maybe<Array<Maybe<FulfillmentItem>>>;
  shippingAddress?: Maybe<Address>;
  tracker?: Maybe<Tracker>;
};

export type FulfillmentItem = {
  __typename?: 'FulfillmentItem';
  id?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type Tracker = {
  __typename?: 'Tracker';
  carrier?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
};

export type LineItem = {
  __typename?: 'LineItem';
  customAttributes?: Maybe<Scalars['AWSJSON']>;
  finalPrice?: Maybe<Price>;
  fulfillmentLineItems?: Maybe<Array<Maybe<FulfillmentLineItem>>>;
  imageUrl?: Maybe<Scalars['String']>;
  lineItemId?: Maybe<Scalars['String']>;
  originalPrice?: Maybe<Price>;
  productId?: Maybe<Scalars['String']>;
  productTitle?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Array<Maybe<Tax>>>;
  totalPrice?: Maybe<Price>;
  variantId?: Maybe<Scalars['String']>;
  variantTitle?: Maybe<Scalars['String']>;
  variantType?: Maybe<VariantTypeEnum>;
};

export type FulfillmentLineItem = {
  __typename?: 'FulfillmentLineItem';
  lineItemId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type Tax = {
  __typename?: 'Tax';
  collectionType: TaxCollectionEnum;
  currency?: Maybe<CurrencyEnum>;
  excludedProductVariants: Array<TaxProductVariant>;
  flatRate?: Maybe<Price>;
  percentage?: Maybe<Scalars['Float']>;
  productVariants: Array<TaxProductVariant>;
  taxId: Scalars['String'];
  taxPrice?: Maybe<Price>;
  title: Scalars['String'];
  type?: Maybe<TaxTypeEnum>;
};

export enum TaxCollectionEnum {
  AllProducts = 'ALL_PRODUCTS',
  SelectedProductVariants = 'SELECTED_PRODUCT_VARIANTS'
}

export type TaxProductVariant = {
  __typename?: 'TaxProductVariant';
  createdAt: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  product: Product;
  productId: Scalars['ID'];
  taxId: Scalars['ID'];
  updatedAt: Scalars['String'];
  variant: Variant;
  variantId: Scalars['ID'];
};

export type Product = {
  __typename?: 'Product';
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  optionLabels?: Maybe<Array<Maybe<Scalars['String']>>>;
  productId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  variants: Array<Variant>;
};

export type Variant = {
  __typename?: 'Variant';
  chargeTax?: Maybe<Scalars['Boolean']>;
  comparePrice?: Maybe<Price>;
  createdAt?: Maybe<Scalars['String']>;
  disableAutomaticDiscount?: Maybe<Scalars['Boolean']>;
  discountedPackagePrice?: Maybe<Price>;
  discountedPrice?: Maybe<Price>;
  discountedUpsellPrice?: Maybe<Price>;
  imageUrl?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  internalPackagePrice?: Maybe<Price>;
  internalPrice?: Maybe<Price>;
  internalUpsellPrice?: Maybe<Price>;
  isOversellable?: Maybe<Scalars['Boolean']>;
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  optionLabels?: Maybe<Array<Maybe<Scalars['String']>>>;
  packagePrice?: Maybe<Price>;
  price?: Maybe<Price>;
  productId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trackQuantity?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['String']>;
  upsellPrice?: Maybe<Price>;
  variantId?: Maybe<Scalars['String']>;
  variantType?: Maybe<VariantTypeEnum>;
};

export enum VariantTypeEnum {
  Physical = 'physical',
  Virtual = 'virtual'
}

export enum TaxTypeEnum {
  FlatRate = 'flatRate',
  Percentage = 'percentage'
}

export enum PaymentStatusEnum {
  Paid = 'paid',
  PartiallyRefunded = 'partially_refunded',
  Processing = 'processing',
  Refunded = 'refunded',
  RequiresAction = 'requires_action',
  Unpaid = 'unpaid'
}

export type Refund = {
  __typename?: 'Refund';
  amount?: Maybe<Price>;
  chargeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  createdRefundAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lineItems?: Maybe<Array<Maybe<RefundLineItem>>>;
  reason?: Maybe<Scalars['String']>;
  receiptNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type RefundLineItem = {
  __typename?: 'RefundLineItem';
  id: Scalars['String'];
  quantity: Scalars['Int'];
};

export type ShippingMethod = {
  __typename?: 'ShippingMethod';
  zone?: Maybe<ShippingMethodZone>;
};

export type ShippingMethodZone = {
  __typename?: 'ShippingMethodZone';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rate?: Maybe<ShippingRate>;
};

export type ShippingRate = {
  __typename?: 'ShippingRate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
};

export enum OrderStatusEnum {
  Archived = 'archived',
  Canceled = 'canceled',
  Open = 'open'
}

export type Prices = {
  __typename?: 'Prices';
  fee?: Maybe<Price>;
  finalSubTotalPrice?: Maybe<Price>;
  lineItemsRefund?: Maybe<Price>;
  netPayment?: Maybe<Price>;
  originalSubTotalPrice?: Maybe<Price>;
  shippingPrice?: Maybe<Price>;
  shippingRefund?: Maybe<Price>;
  taxPrice?: Maybe<Price>;
  taxRefund?: Maybe<Price>;
  totalChargebackFee?: Maybe<Price>;
  totalChargebacks?: Maybe<Price>;
  totalDiscountPrice?: Maybe<Price>;
  totalPrice?: Maybe<Price>;
  totalRefund?: Maybe<Price>;
};

export type ShippingProfile = {
  __typename?: 'ShippingProfile';
  currency: CurrencyEnum;
  id: Scalars['ID'];
  zones: Array<ShippingZone>;
};

export type ShippingZone = {
  __typename?: 'ShippingZone';
  countries: Array<ShipingCountry>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rates: Array<ShippingRate>;
};

export type ShipingCountry = {
  __typename?: 'ShipingCountry';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  restOfWorld: Scalars['Boolean'];
};

export type ShippingCurrenciesResponse = {
  __typename?: 'ShippingCurrenciesResponse';
  profiles: Array<ShippingProfile>;
};

export type TaxCurrenciesResponse = {
  __typename?: 'TaxCurrenciesResponse';
  currencies: Array<Price>;
};

export type GetTaxesResponse = {
  __typename?: 'GetTaxesResponse';
  hasMoreResults: Scalars['Boolean'];
  taxes: Array<Tax>;
};

export type GetTaxesForProductVariantResponse = {
  __typename?: 'GetTaxesForProductVariantResponse';
  taxes: Array<Tax>;
};

export type Mutation = {
  __typename?: 'Mutation';
  archiveOrder: Order;
  cancelFulfillment: Order;
  cancelOrder: Order;
  createCustomer: Customer;
  createDiscount?: Maybe<Discount>;
  createExcludedTaxProductVariant: CreateExcludedTaxProductVariantResult;
  createMarket: Market;
  createOrder: Order;
  createOrderFulfillmentGroup?: Maybe<OrderFulfillmentGroup>;
  createPresignedUrl: Assets;
  createProduct: Product;
  createTax: Tax;
  createTaxProductVariant?: Maybe<TaxProductVariant>;
  createVariant: Variant;
  deleteDiscount?: Maybe<DiscountSuccess>;
  deleteDiscounts?: Maybe<DiscountSuccess>;
  deleteExcludedTaxProductVariant: DeleteExcludedTaxProductVariantResult;
  deleteLineItemCustomAttributeKey: Order;
  deleteMarket: DeleteSuccess;
  deleteOrderFulfillmentGroup: DeleteSuccess;
  deleteProduct: DeleteSuccess;
  deleteTax: DeleteSuccess;
  deleteTaxProductVariant?: Maybe<TaxProductVariant>;
  deleteVariant: Variant;
  disableDiscounts?: Maybe<DiscountSuccess>;
  enableDiscounts?: Maybe<DiscountSuccess>;
  exportOrders?: Maybe<Scalars['String']>;
  fulfillLineItems: Order;
  refundOrder: Order;
  resetMarketProductPrice: MarketSuccess;
  restockLineItems: Order;
  setCustomerBillingAddress: Order;
  setCustomerShippingAddress: Order;
  setDefaultCurrencyMarketProductPrices: MarketSuccess;
  setMarketCurrencyMarketProductPrices: MarketSuccess;
  setTrackToFulfillment: Order;
  syncDiscountCurrencies: Array<Discount>;
  syncMarketProductPrice: MarketSuccess;
  syncShippingCurrencies: Array<ShippingProfile>;
  syncTaxCurrencies: Array<Tax>;
  updateCustomer: Order;
  updateCustomerInfo: Customer;
  updateDiscount?: Maybe<Discount>;
  updateDiscountCurrencies: DiscountCurrenciesResponse;
  updateMarket: Market;
  updateMarketCountries: MarketSuccess;
  updateMarketPrice: MarketSuccess;
  updateOrderFulfillmentGroup?: Maybe<OrderFulfillmentGroup>;
  updateProduct: Product;
  updateShipping: ShippingProfile;
  updateShippingCurrencies: ShippingCurrenciesResponse;
  updateTax: Tax;
  updateTaxCurrencies: TaxCurrenciesResponse;
  updateVariant: Variant;
  validateEmail?: Maybe<ValidationResult>;
};


export type MutationArchiveOrderArgs = {
  orderId: Scalars['ID'];
};


export type MutationCancelFulfillmentArgs = {
  fulfillmentId: Scalars['ID'];
  orderId: Scalars['ID'];
};


export type MutationCancelOrderArgs = {
  notifyCustomer?: InputMaybe<Scalars['Boolean']>;
  orderId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationCreateCustomerArgs = {
  customer: CreateCustomerInput;
};


export type MutationCreateDiscountArgs = {
  input: DiscountFormInput;
};


export type MutationCreateExcludedTaxProductVariantArgs = {
  input: CreateExcludedTaxProductVariantInput;
};


export type MutationCreateMarketArgs = {
  market: MarketInput;
};


export type MutationCreateOrderArgs = {
  order: OrderInput;
};


export type MutationCreateOrderFulfillmentGroupArgs = {
  orderFulfillment: OrderFulfillmentGroupFormInput;
};


export type MutationCreatePresignedUrlArgs = {
  assets: AssetsInput;
  prefixKey: Scalars['String'];
};


export type MutationCreateProductArgs = {
  product: ProductInput;
};


export type MutationCreateTaxArgs = {
  input: CreateTaxInput;
};


export type MutationCreateTaxProductVariantArgs = {
  input: CreateTaxProductVariantInput;
  taxId: Scalars['ID'];
};


export type MutationCreateVariantArgs = {
  productId: Scalars['ID'];
  variant: VariantInput;
};


export type MutationDeleteDiscountArgs = {
  discountId: Scalars['ID'];
};


export type MutationDeleteDiscountsArgs = {
  discountIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDeleteExcludedTaxProductVariantArgs = {
  input: DeleteExcludedTaxProductVariantInput;
};


export type MutationDeleteLineItemCustomAttributeKeyArgs = {
  attributeKey: Scalars['String'];
  lineItemId: Scalars['ID'];
  orderId: Scalars['ID'];
};


export type MutationDeleteMarketArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrderFulfillmentGroupArgs = {
  orderFulfillmentGroupId: Scalars['ID'];
};


export type MutationDeleteProductArgs = {
  productId: Scalars['ID'];
};


export type MutationDeleteTaxArgs = {
  taxId: Scalars['ID'];
};


export type MutationDeleteTaxProductVariantArgs = {
  input: DeleteTaxProductVariantInput;
};


export type MutationDeleteVariantArgs = {
  productId: Scalars['ID'];
  variantId: Scalars['ID'];
};


export type MutationDisableDiscountsArgs = {
  discountIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationEnableDiscountsArgs = {
  discountIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationExportOrdersArgs = {
  input: ExportOrdersInput;
};


export type MutationFulfillLineItemsArgs = {
  fulfillment: OrderFulfillmentInput;
  orderId: Scalars['ID'];
};


export type MutationRefundOrderArgs = {
  orderId: Scalars['ID'];
  refund: RefundParamsInput;
};


export type MutationResetMarketProductPriceArgs = {
  id: Scalars['ID'];
  productId: Scalars['ID'];
};


export type MutationRestockLineItemsArgs = {
  orderId: Scalars['ID'];
  restocks: Array<InputMaybe<RestockedLineItemInput>>;
};


export type MutationSetCustomerBillingAddressArgs = {
  billingAddress: OrderBillingAddressInput;
  orderId: Scalars['ID'];
};


export type MutationSetCustomerShippingAddressArgs = {
  orderId: Scalars['ID'];
  shippingAddress: OrderShippingAddressInput;
};


export type MutationSetDefaultCurrencyMarketProductPricesArgs = {
  id: Scalars['ID'];
  input: DefaultCurrencyMarketInput;
};


export type MutationSetMarketCurrencyMarketProductPricesArgs = {
  id: Scalars['ID'];
  input: DefaultCurrencyMarketInput;
};


export type MutationSetTrackToFulfillmentArgs = {
  fulfillmentId: Scalars['ID'];
  orderId: Scalars['ID'];
  tracker: OrderTrackerInput;
};


export type MutationSyncDiscountCurrenciesArgs = {
  discountId: Scalars['ID'];
};


export type MutationSyncMarketProductPriceArgs = {
  productId: Scalars['ID'];
};


export type MutationSyncShippingCurrenciesArgs = {
  shippingId: Scalars['ID'];
};


export type MutationSyncTaxCurrenciesArgs = {
  taxId: Scalars['ID'];
};


export type MutationUpdateCustomerArgs = {
  customer: OrderCustomerBodyInput;
  orderId: Scalars['ID'];
};


export type MutationUpdateCustomerInfoArgs = {
  customer: CustomerInput;
  customerId: Scalars['ID'];
};


export type MutationUpdateDiscountArgs = {
  discountId: Scalars['ID'];
  input: DiscountFormInput;
};


export type MutationUpdateDiscountCurrenciesArgs = {
  discountId: Scalars['ID'];
  input: Array<DiscountFormInput>;
};


export type MutationUpdateMarketArgs = {
  id: Scalars['ID'];
  market: MarketInput;
};


export type MutationUpdateMarketCountriesArgs = {
  id: Scalars['ID'];
  input: MarketCountriesInput;
};


export type MutationUpdateMarketPriceArgs = {
  id: Scalars['ID'];
  input: MarketPriceInput;
};


export type MutationUpdateOrderFulfillmentGroupArgs = {
  orderFulfillment: OrderFulfillmentGroupFormInput;
  orderFulfillmentGroupId: Scalars['ID'];
};


export type MutationUpdateProductArgs = {
  product: ProductInput;
  productId: Scalars['ID'];
};


export type MutationUpdateShippingArgs = {
  input: ShippingProfileFormInput;
};


export type MutationUpdateShippingCurrenciesArgs = {
  input: Array<ShippingProfileFormInput>;
  shippingId: Scalars['ID'];
};


export type MutationUpdateTaxArgs = {
  input: UpdateTaxInput;
};


export type MutationUpdateTaxCurrenciesArgs = {
  input: Array<TaxCurrenciesInput>;
  taxId: Scalars['ID'];
};


export type MutationUpdateVariantArgs = {
  productId: Scalars['ID'];
  variant: VariantInput;
  variantId: Scalars['ID'];
};


export type MutationValidateEmailArgs = {
  email: Scalars['String'];
};

export type CreateCustomerInput = {
  billingAddress?: InputMaybe<AddressInput>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<AddressInput>;
};

export type AddressInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  addressCode: Scalars['String'];
  city: Scalars['String'];
  company?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  division?: InputMaybe<Scalars['String']>;
  divisionCode?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
};

export type DiscountFormInput = {
  currency: CurrencyEnum;
  name: Scalars['String'];
  rules: DiscountRulesInput;
  status?: InputMaybe<DiscountStatusType>;
  summary?: InputMaybe<Scalars['String']>;
  type: DiscountType;
  usage?: InputMaybe<Scalars['Int']>;
};

export type DiscountRulesInput = {
  endsAt?: InputMaybe<Scalars['String']>;
  entitledCountryCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  entitledProductVariantIds?: InputMaybe<Array<InputMaybe<DiscountProductVariantIdInput>>>;
  prerequisiteCustomerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  prerequisiteMinPurchaseAmount?: InputMaybe<PriceInput>;
  prerequisiteMinQuantity?: InputMaybe<Scalars['Int']>;
  prerequisiteProductVariantIds?: InputMaybe<Array<InputMaybe<DiscountProductVariantIdInput>>>;
  prerequisiteToEntitlement?: InputMaybe<DiscountPrerequisiteToEntitlementInput>;
  startsAt: Scalars['String'];
  type: DiscountRuleType;
  usageLimit?: InputMaybe<Scalars['Int']>;
  usageOncePerUser?: InputMaybe<Scalars['Boolean']>;
  value: Scalars['AWSJSON'];
};

export type DiscountProductVariantIdInput = {
  productId: Scalars['String'];
  variantId: Scalars['String'];
};

export type PriceInput = {
  amount: Scalars['Int'];
  currency: CurrencyEnum;
};

export type DiscountPrerequisiteToEntitlementInput = {
  entitledQuantity?: InputMaybe<Scalars['Int']>;
  maxNumberOfUses?: InputMaybe<Scalars['Int']>;
  prerequisitePurchaseAmount?: InputMaybe<PriceInput>;
  prerequisiteQuantity?: InputMaybe<Scalars['Int']>;
};

export type CreateExcludedTaxProductVariantInput = {
  productVariantForm: CreateExcludedTaxProductVariantFormInput;
  taxId: Scalars['ID'];
};

export type CreateExcludedTaxProductVariantFormInput = {
  productId: Scalars['ID'];
  variantId: Scalars['ID'];
};

export type CreateExcludedTaxProductVariantResult = {
  __typename?: 'CreateExcludedTaxProductVariantResult';
  productId: Scalars['ID'];
  taxId?: Maybe<Scalars['ID']>;
  variantId: Scalars['ID'];
};

export type MarketInput = {
  countries: Array<CountryInput>;
  currency: CurrencyEnum;
  title: Scalars['String'];
};

export type CountryInput = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type OrderInput = {
  charge?: InputMaybe<ChargeInput>;
  customAttributes?: InputMaybe<CustomAttributesInput>;
  customer: CustomerInput;
  lineItems: Array<InputMaybe<LineItemInput>>;
  netPayment?: InputMaybe<Scalars['String']>;
  refunds?: InputMaybe<Array<InputMaybe<RefundInput>>>;
  status?: InputMaybe<OrderStatusEnum>;
  total?: InputMaybe<PriceInput>;
  totalRefund?: InputMaybe<Scalars['String']>;
};

export type ChargeInput = {
  accountId: Scalars['String'];
  amount?: InputMaybe<PriceInput>;
  amountRefunded?: InputMaybe<PriceInput>;
  description: Scalars['String'];
  id: Scalars['String'];
  isDisputed: Scalars['Boolean'];
  isLivemode: Scalars['Boolean'];
  isPaid: Scalars['Boolean'];
  isRefunded: Scalars['Boolean'];
  paymentMethod?: InputMaybe<PaymentMethodInput>;
  receiptNumber: Scalars['String'];
  service: PaymentServiceEnum;
  status?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type PaymentMethodInput = {
  card: CardInput;
  type: Scalars['String'];
};

export type CardInput = {
  country: Scalars['String'];
  expMonth: Scalars['String'];
  expYear: Scalars['Int'];
  last4: Scalars['String'];
  network: Scalars['String'];
};

export type CustomAttributesInput = {
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type CustomerInput = {
  billingAddress?: InputMaybe<AddressInput>;
  customerId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<AddressInput>;
};

export type LineItemInput = {
  finalPrice?: InputMaybe<PriceInput>;
  fulfillmentItemInputs?: InputMaybe<Array<InputMaybe<FulfillmentLineItemInput>>>;
  imageUrl?: InputMaybe<Scalars['String']>;
  lineItemId: Scalars['String'];
  originalPrice?: InputMaybe<PriceInput>;
  productId: Scalars['String'];
  productTitle: Scalars['String'];
  quantity: Scalars['Int'];
  restockedLineItems?: InputMaybe<Array<InputMaybe<RestockedLineItemInput>>>;
  totalPrice: PriceInput;
  variantId: Scalars['String'];
  variantTitle: Scalars['String'];
  variantType: VariantTypeEnum;
};

export type FulfillmentLineItemInput = {
  lineItemId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type RestockedLineItemInput = {
  lineItemId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type RefundInput = {
  amount: PriceInput;
  chargeId: Scalars['String'];
  createdRefundAt?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  metadata?: InputMaybe<Metadata>;
  reason?: InputMaybe<Scalars['String']>;
  receiptNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<RefundStatusEnum>;
};

export type Metadata = {
  key?: InputMaybe<Scalars['String']>;
  variantTitle?: InputMaybe<Scalars['String']>;
};

export enum RefundStatusEnum {
  RefundCreated = 'RefundCreated',
  RefundDeleted = 'RefundDeleted',
  RefundUpdated = 'RefundUpdated'
}

export type OrderFulfillmentGroupFormInput = {
  imageUrl?: InputMaybe<Scalars['String']>;
  productIds: Array<Scalars['ID']>;
  title: Scalars['String'];
};

export type AssetsInput = {
  contentType: Scalars['String'];
  fileName: Scalars['String'];
};

export type Assets = {
  __typename?: 'Assets';
  bucketName: Scalars['String'];
  fileName: Scalars['String'];
  url: Scalars['String'];
};

export type ProductInput = {
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  optionLabels: Array<InputMaybe<Scalars['String']>>;
  productId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  variants: Array<VariantInput>;
};

export type VariantInput = {
  chargeTax: Scalars['Boolean'];
  comparePrice?: InputMaybe<PriceInput>;
  disableAutomaticDiscount?: InputMaybe<Scalars['Boolean']>;
  discountedPackagePrice?: InputMaybe<PriceInput>;
  discountedPrice?: InputMaybe<PriceInput>;
  discountedUpsellPrice?: InputMaybe<PriceInput>;
  imageUrl?: InputMaybe<Scalars['String']>;
  index: Scalars['Int'];
  internalPackagePrice?: InputMaybe<PriceInput>;
  internalPrice?: InputMaybe<PriceInput>;
  internalUpsellPrice?: InputMaybe<PriceInput>;
  isOversellable: Scalars['Boolean'];
  option1: Scalars['String'];
  option2?: InputMaybe<Scalars['String']>;
  option3?: InputMaybe<Scalars['String']>;
  optionLabels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  packagePrice?: InputMaybe<PriceInput>;
  price: PriceInput;
  quantity: Scalars['Int'];
  sku?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  trackQuantity: Scalars['Boolean'];
  upsellPrice?: InputMaybe<PriceInput>;
  variantId?: InputMaybe<Scalars['String']>;
  variantType: VariantTypeEnum;
};

export type CreateTaxInput = {
  collectionType: TaxCollectionEnum;
  currency: CurrencyEnum;
  flatRate?: InputMaybe<PriceInput>;
  percentage?: InputMaybe<Scalars['Float']>;
  productVariants?: InputMaybe<Array<TaxProductVariantInput>>;
  title: Scalars['String'];
  type: TaxTypeEnum;
};

export type TaxProductVariantInput = {
  isActive: Scalars['Boolean'];
  productId: Scalars['ID'];
  taxId?: InputMaybe<Scalars['ID']>;
  variantId: Scalars['ID'];
};

export type CreateTaxProductVariantInput = {
  isActive: Scalars['Boolean'];
  productId: Scalars['ID'];
  variantId: Scalars['ID'];
};

export type DiscountSuccess = {
  __typename?: 'DiscountSuccess';
  successful?: Maybe<Scalars['Boolean']>;
};

export type DeleteExcludedTaxProductVariantInput = {
  productId: Scalars['ID'];
  taxId: Scalars['ID'];
  variantId: Scalars['ID'];
};

export type DeleteExcludedTaxProductVariantResult = {
  __typename?: 'DeleteExcludedTaxProductVariantResult';
  productId: Scalars['ID'];
  taxID?: Maybe<Scalars['ID']>;
  variantId: Scalars['ID'];
};

export type DeleteSuccess = {
  __typename?: 'DeleteSuccess';
  isDeleteSuccess?: Maybe<Scalars['Boolean']>;
};

export type DeleteTaxProductVariantInput = {
  productId: Scalars['ID'];
  taxId: Scalars['ID'];
  variantId: Scalars['ID'];
};

export type ExportOrdersInput = {
  elasticSearchQueryString: Scalars['String'];
  email: Scalars['String'];
};

export type OrderFulfillmentInput = {
  fulfillment: FulfillmentInput;
};

export type FulfillmentInput = {
  id: Scalars['String'];
  lineItems: Array<InputMaybe<FulfillmentItemInput>>;
  shippingAddress?: InputMaybe<AddressInput>;
  tracker?: InputMaybe<TrackerInput>;
};

export type FulfillmentItemInput = {
  id: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type TrackerInput = {
  carrier: Scalars['String'];
  trackingNumber: Scalars['String'];
};

export type RefundParamsInput = {
  params: RefundCreateParamsInput;
};

export type RefundCreateParamsInput = {
  isCancellation?: InputMaybe<Scalars['Boolean']>;
  lineItems?: InputMaybe<Array<InputMaybe<RefundLineItemInput>>>;
  lineItemsRefundAmount: PriceInput;
  metadata?: InputMaybe<Scalars['AWSJSON']>;
  notifyCustomer?: InputMaybe<Scalars['Boolean']>;
  reason?: InputMaybe<Scalars['String']>;
  shippingRefundAmount: PriceInput;
  taxRefundAmount: PriceInput;
};

export type RefundLineItemInput = {
  id: Scalars['String'];
  quantity: Scalars['Int'];
};

export type MarketSuccess = {
  __typename?: 'MarketSuccess';
  successful?: Maybe<Scalars['Boolean']>;
};

export type OrderBillingAddressInput = {
  billingAddress: AddressInput;
};

export type OrderShippingAddressInput = {
  shippingAddress: AddressInput;
};

export type DefaultCurrencyMarketInput = {
  productIds: Array<Scalars['String']>;
};

export type OrderTrackerInput = {
  notifyCustomer?: InputMaybe<Scalars['Boolean']>;
  tracker: TrackerInput;
};

export type OrderCustomerBodyInput = {
  customer?: InputMaybe<OrderCustomerInput>;
};

export type OrderCustomerInput = {
  billingAddress?: InputMaybe<AddressInput>;
  email?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<AddressInput>;
};

export type MarketCountriesInput = {
  countries: Array<CountryInput>;
};

export type MarketPriceInput = {
  products: Array<MarketPriceProductInput>;
  variants: Array<MarketPriceVariantInput>;
};

export type MarketPriceProductInput = {
  comparePrice?: InputMaybe<PriceInput>;
  discountedPackagePrice?: InputMaybe<PriceInput>;
  discountedPrice?: InputMaybe<PriceInput>;
  discountedUpsellPrice?: InputMaybe<PriceInput>;
  internalPackagePrice?: InputMaybe<PriceInput>;
  internalPrice?: InputMaybe<PriceInput>;
  internalUpsellPrice?: InputMaybe<PriceInput>;
  packagePrice?: InputMaybe<PriceInput>;
  price?: InputMaybe<PriceInput>;
  productId: Scalars['String'];
  upsellPrice?: InputMaybe<PriceInput>;
};

export type MarketPriceVariantInput = {
  comparePrice?: InputMaybe<PriceInput>;
  discountedPackagePrice?: InputMaybe<PriceInput>;
  discountedPrice?: InputMaybe<PriceInput>;
  discountedUpsellPrice?: InputMaybe<PriceInput>;
  internalPackagePrice?: InputMaybe<PriceInput>;
  internalPrice?: InputMaybe<PriceInput>;
  internalUpsellPrice?: InputMaybe<PriceInput>;
  packagePrice?: InputMaybe<PriceInput>;
  price?: InputMaybe<PriceInput>;
  productId: Scalars['String'];
  upsellPrice?: InputMaybe<PriceInput>;
  variantId: Scalars['String'];
};

export type ShippingProfileFormInput = {
  currency: CurrencyEnum;
  zones: Array<ShippingZoneInput>;
};

export type ShippingZoneInput = {
  countries: Array<ShipingCountryInput>;
  id: Scalars['String'];
  name: Scalars['String'];
  rates: Array<ShippingRateInput>;
};

export type ShipingCountryInput = {
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  restOfWorld: Scalars['Boolean'];
};

export type ShippingRateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  price: PriceInput;
};

export type UpdateTaxInput = {
  collectionType?: InputMaybe<TaxCollectionEnum>;
  currency: CurrencyEnum;
  flatRate?: InputMaybe<PriceInput>;
  percentage?: InputMaybe<Scalars['String']>;
  productVariants?: InputMaybe<Array<TaxProductVariantInput>>;
  taxId: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TaxTypeEnum>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export type TaxCurrenciesInput = {
  amount: Scalars['Float'];
  currency: CurrencyEnum;
};

export type ValidationResult = {
  __typename?: 'ValidationResult';
  isValid?: Maybe<Scalars['Boolean']>;
};

export enum CancellationReasonEnum {
  CustomerChangesOrCanceledOrder = 'CustomerChangesOrCanceledOrder',
  FraudulentOrder = 'FraudulentOrder',
  ItemsUnavailable = 'ItemsUnavailable',
  Others = 'Others',
  PaymentDeclined = 'PaymentDeclined'
}

export type CheckEmail = {
  email: Scalars['String'];
};

export type DiscountCurrenciesInput = {
  amount: Scalars['Float'];
  currency: CurrencyEnum;
};

export type DiscountResponse = {
  __typename?: 'DiscountResponse';
  discount?: Maybe<Discount>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Query: ResolverTypeWrapper<{}>;
  CurrencyEnum: CurrencyEnum;
  GetCurrencyPairResponse: ResolverTypeWrapper<GetCurrencyPairResponse>;
  CurrencyPair: ResolverTypeWrapper<CurrencyPair>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Customer: ResolverTypeWrapper<Customer>;
  Analytics: ResolverTypeWrapper<Analytics>;
  Price: ResolverTypeWrapper<Price>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Address: ResolverTypeWrapper<Address>;
  LastOrder: ResolverTypeWrapper<LastOrder>;
  MarketingLevelEnum: MarketingLevelEnum;
  OrdersCount: ResolverTypeWrapper<OrdersCount>;
  Discount: ResolverTypeWrapper<Discount>;
  DiscountRules: ResolverTypeWrapper<Omit<DiscountRules, 'value'> & { value?: Maybe<ResolversTypes['DiscountValue']> }>;
  DiscountProductVariantId: ResolverTypeWrapper<DiscountProductVariantId>;
  DiscountPrerequisiteToEntitlement: ResolverTypeWrapper<DiscountPrerequisiteToEntitlement>;
  DiscountRuleType: DiscountRuleType;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  DiscountValue: ResolversTypes['DiscountPriceValue'] | ResolversTypes['DiscountScalarValue'];
  DiscountPriceValue: ResolverTypeWrapper<DiscountPriceValue>;
  DiscountValueType: DiscountValueType;
  DiscountScalarValue: ResolverTypeWrapper<DiscountScalarValue>;
  DiscountStatusType: DiscountStatusType;
  DiscountType: DiscountType;
  DiscountCurrenciesResponse: ResolverTypeWrapper<DiscountCurrenciesResponse>;
  OrderFulfillmentGroup: ResolverTypeWrapper<OrderFulfillmentGroup>;
  FulfillmentStatusEnum: FulfillmentStatusEnum;
  Market: ResolverTypeWrapper<Market>;
  Country: ResolverTypeWrapper<Country>;
  Order: ResolverTypeWrapper<Order>;
  Charge: ResolverTypeWrapper<Charge>;
  PaymentMethod: ResolverTypeWrapper<PaymentMethod>;
  Card: ResolverTypeWrapper<Card>;
  Risk: ResolverTypeWrapper<Risk>;
  RiskLevelEnum: RiskLevelEnum;
  RiskReasonEnum: RiskReasonEnum;
  RiskStatusEnum: RiskStatusEnum;
  RiskTypeEnum: RiskTypeEnum;
  PaymentServiceEnum: PaymentServiceEnum;
  AWSJSON: ResolverTypeWrapper<Scalars['AWSJSON']>;
  Dispute: ResolverTypeWrapper<Dispute>;
  DisputeEvidenceDetails: ResolverTypeWrapper<DisputeEvidenceDetails>;
  DisputeStatusEnum: DisputeStatusEnum;
  ExchangeRate: ResolverTypeWrapper<ExchangeRate>;
  FraudDetection: ResolverTypeWrapper<FraudDetection>;
  Fulfillment: ResolverTypeWrapper<Fulfillment>;
  FulfillmentItem: ResolverTypeWrapper<FulfillmentItem>;
  Tracker: ResolverTypeWrapper<Tracker>;
  LineItem: ResolverTypeWrapper<LineItem>;
  FulfillmentLineItem: ResolverTypeWrapper<FulfillmentLineItem>;
  Tax: ResolverTypeWrapper<Tax>;
  TaxCollectionEnum: TaxCollectionEnum;
  TaxProductVariant: ResolverTypeWrapper<TaxProductVariant>;
  Product: ResolverTypeWrapper<Product>;
  Variant: ResolverTypeWrapper<Variant>;
  VariantTypeEnum: VariantTypeEnum;
  TaxTypeEnum: TaxTypeEnum;
  PaymentStatusEnum: PaymentStatusEnum;
  Refund: ResolverTypeWrapper<Refund>;
  RefundLineItem: ResolverTypeWrapper<RefundLineItem>;
  ShippingMethod: ResolverTypeWrapper<ShippingMethod>;
  ShippingMethodZone: ResolverTypeWrapper<ShippingMethodZone>;
  ShippingRate: ResolverTypeWrapper<ShippingRate>;
  OrderStatusEnum: OrderStatusEnum;
  Prices: ResolverTypeWrapper<Prices>;
  ShippingProfile: ResolverTypeWrapper<ShippingProfile>;
  ShippingZone: ResolverTypeWrapper<ShippingZone>;
  ShipingCountry: ResolverTypeWrapper<ShipingCountry>;
  ShippingCurrenciesResponse: ResolverTypeWrapper<ShippingCurrenciesResponse>;
  TaxCurrenciesResponse: ResolverTypeWrapper<TaxCurrenciesResponse>;
  GetTaxesResponse: ResolverTypeWrapper<GetTaxesResponse>;
  GetTaxesForProductVariantResponse: ResolverTypeWrapper<GetTaxesForProductVariantResponse>;
  Mutation: ResolverTypeWrapper<{}>;
  CreateCustomerInput: CreateCustomerInput;
  AddressInput: AddressInput;
  DiscountFormInput: DiscountFormInput;
  DiscountRulesInput: DiscountRulesInput;
  DiscountProductVariantIdInput: DiscountProductVariantIdInput;
  PriceInput: PriceInput;
  DiscountPrerequisiteToEntitlementInput: DiscountPrerequisiteToEntitlementInput;
  CreateExcludedTaxProductVariantInput: CreateExcludedTaxProductVariantInput;
  CreateExcludedTaxProductVariantFormInput: CreateExcludedTaxProductVariantFormInput;
  CreateExcludedTaxProductVariantResult: ResolverTypeWrapper<CreateExcludedTaxProductVariantResult>;
  MarketInput: MarketInput;
  CountryInput: CountryInput;
  OrderInput: OrderInput;
  ChargeInput: ChargeInput;
  PaymentMethodInput: PaymentMethodInput;
  CardInput: CardInput;
  CustomAttributesInput: CustomAttributesInput;
  CustomerInput: CustomerInput;
  LineItemInput: LineItemInput;
  FulfillmentLineItemInput: FulfillmentLineItemInput;
  RestockedLineItemInput: RestockedLineItemInput;
  RefundInput: RefundInput;
  Metadata: Metadata;
  RefundStatusEnum: RefundStatusEnum;
  OrderFulfillmentGroupFormInput: OrderFulfillmentGroupFormInput;
  AssetsInput: AssetsInput;
  Assets: ResolverTypeWrapper<Assets>;
  ProductInput: ProductInput;
  VariantInput: VariantInput;
  CreateTaxInput: CreateTaxInput;
  TaxProductVariantInput: TaxProductVariantInput;
  CreateTaxProductVariantInput: CreateTaxProductVariantInput;
  DiscountSuccess: ResolverTypeWrapper<DiscountSuccess>;
  DeleteExcludedTaxProductVariantInput: DeleteExcludedTaxProductVariantInput;
  DeleteExcludedTaxProductVariantResult: ResolverTypeWrapper<DeleteExcludedTaxProductVariantResult>;
  DeleteSuccess: ResolverTypeWrapper<DeleteSuccess>;
  DeleteTaxProductVariantInput: DeleteTaxProductVariantInput;
  ExportOrdersInput: ExportOrdersInput;
  OrderFulfillmentInput: OrderFulfillmentInput;
  FulfillmentInput: FulfillmentInput;
  FulfillmentItemInput: FulfillmentItemInput;
  TrackerInput: TrackerInput;
  RefundParamsInput: RefundParamsInput;
  RefundCreateParamsInput: RefundCreateParamsInput;
  RefundLineItemInput: RefundLineItemInput;
  MarketSuccess: ResolverTypeWrapper<MarketSuccess>;
  OrderBillingAddressInput: OrderBillingAddressInput;
  OrderShippingAddressInput: OrderShippingAddressInput;
  DefaultCurrencyMarketInput: DefaultCurrencyMarketInput;
  OrderTrackerInput: OrderTrackerInput;
  OrderCustomerBodyInput: OrderCustomerBodyInput;
  OrderCustomerInput: OrderCustomerInput;
  MarketCountriesInput: MarketCountriesInput;
  MarketPriceInput: MarketPriceInput;
  MarketPriceProductInput: MarketPriceProductInput;
  MarketPriceVariantInput: MarketPriceVariantInput;
  ShippingProfileFormInput: ShippingProfileFormInput;
  ShippingZoneInput: ShippingZoneInput;
  ShipingCountryInput: ShipingCountryInput;
  ShippingRateInput: ShippingRateInput;
  UpdateTaxInput: UpdateTaxInput;
  TaxCurrenciesInput: TaxCurrenciesInput;
  ValidationResult: ResolverTypeWrapper<ValidationResult>;
  CancellationReasonEnum: CancellationReasonEnum;
  CheckEmail: CheckEmail;
  DiscountCurrenciesInput: DiscountCurrenciesInput;
  DiscountResponse: ResolverTypeWrapper<DiscountResponse>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Query: {};
  GetCurrencyPairResponse: GetCurrencyPairResponse;
  CurrencyPair: CurrencyPair;
  String: Scalars['String'];
  Float: Scalars['Float'];
  ID: Scalars['ID'];
  Customer: Customer;
  Analytics: Analytics;
  Price: Price;
  Int: Scalars['Int'];
  Address: Address;
  LastOrder: LastOrder;
  OrdersCount: OrdersCount;
  Discount: Discount;
  DiscountRules: Omit<DiscountRules, 'value'> & { value?: Maybe<ResolversParentTypes['DiscountValue']> };
  DiscountProductVariantId: DiscountProductVariantId;
  DiscountPrerequisiteToEntitlement: DiscountPrerequisiteToEntitlement;
  Boolean: Scalars['Boolean'];
  DiscountValue: ResolversParentTypes['DiscountPriceValue'] | ResolversParentTypes['DiscountScalarValue'];
  DiscountPriceValue: DiscountPriceValue;
  DiscountScalarValue: DiscountScalarValue;
  DiscountCurrenciesResponse: DiscountCurrenciesResponse;
  OrderFulfillmentGroup: OrderFulfillmentGroup;
  Market: Market;
  Country: Country;
  Order: Order;
  Charge: Charge;
  PaymentMethod: PaymentMethod;
  Card: Card;
  Risk: Risk;
  AWSJSON: Scalars['AWSJSON'];
  Dispute: Dispute;
  DisputeEvidenceDetails: DisputeEvidenceDetails;
  ExchangeRate: ExchangeRate;
  FraudDetection: FraudDetection;
  Fulfillment: Fulfillment;
  FulfillmentItem: FulfillmentItem;
  Tracker: Tracker;
  LineItem: LineItem;
  FulfillmentLineItem: FulfillmentLineItem;
  Tax: Tax;
  TaxProductVariant: TaxProductVariant;
  Product: Product;
  Variant: Variant;
  Refund: Refund;
  RefundLineItem: RefundLineItem;
  ShippingMethod: ShippingMethod;
  ShippingMethodZone: ShippingMethodZone;
  ShippingRate: ShippingRate;
  Prices: Prices;
  ShippingProfile: ShippingProfile;
  ShippingZone: ShippingZone;
  ShipingCountry: ShipingCountry;
  ShippingCurrenciesResponse: ShippingCurrenciesResponse;
  TaxCurrenciesResponse: TaxCurrenciesResponse;
  GetTaxesResponse: GetTaxesResponse;
  GetTaxesForProductVariantResponse: GetTaxesForProductVariantResponse;
  Mutation: {};
  CreateCustomerInput: CreateCustomerInput;
  AddressInput: AddressInput;
  DiscountFormInput: DiscountFormInput;
  DiscountRulesInput: DiscountRulesInput;
  DiscountProductVariantIdInput: DiscountProductVariantIdInput;
  PriceInput: PriceInput;
  DiscountPrerequisiteToEntitlementInput: DiscountPrerequisiteToEntitlementInput;
  CreateExcludedTaxProductVariantInput: CreateExcludedTaxProductVariantInput;
  CreateExcludedTaxProductVariantFormInput: CreateExcludedTaxProductVariantFormInput;
  CreateExcludedTaxProductVariantResult: CreateExcludedTaxProductVariantResult;
  MarketInput: MarketInput;
  CountryInput: CountryInput;
  OrderInput: OrderInput;
  ChargeInput: ChargeInput;
  PaymentMethodInput: PaymentMethodInput;
  CardInput: CardInput;
  CustomAttributesInput: CustomAttributesInput;
  CustomerInput: CustomerInput;
  LineItemInput: LineItemInput;
  FulfillmentLineItemInput: FulfillmentLineItemInput;
  RestockedLineItemInput: RestockedLineItemInput;
  RefundInput: RefundInput;
  Metadata: Metadata;
  OrderFulfillmentGroupFormInput: OrderFulfillmentGroupFormInput;
  AssetsInput: AssetsInput;
  Assets: Assets;
  ProductInput: ProductInput;
  VariantInput: VariantInput;
  CreateTaxInput: CreateTaxInput;
  TaxProductVariantInput: TaxProductVariantInput;
  CreateTaxProductVariantInput: CreateTaxProductVariantInput;
  DiscountSuccess: DiscountSuccess;
  DeleteExcludedTaxProductVariantInput: DeleteExcludedTaxProductVariantInput;
  DeleteExcludedTaxProductVariantResult: DeleteExcludedTaxProductVariantResult;
  DeleteSuccess: DeleteSuccess;
  DeleteTaxProductVariantInput: DeleteTaxProductVariantInput;
  ExportOrdersInput: ExportOrdersInput;
  OrderFulfillmentInput: OrderFulfillmentInput;
  FulfillmentInput: FulfillmentInput;
  FulfillmentItemInput: FulfillmentItemInput;
  TrackerInput: TrackerInput;
  RefundParamsInput: RefundParamsInput;
  RefundCreateParamsInput: RefundCreateParamsInput;
  RefundLineItemInput: RefundLineItemInput;
  MarketSuccess: MarketSuccess;
  OrderBillingAddressInput: OrderBillingAddressInput;
  OrderShippingAddressInput: OrderShippingAddressInput;
  DefaultCurrencyMarketInput: DefaultCurrencyMarketInput;
  OrderTrackerInput: OrderTrackerInput;
  OrderCustomerBodyInput: OrderCustomerBodyInput;
  OrderCustomerInput: OrderCustomerInput;
  MarketCountriesInput: MarketCountriesInput;
  MarketPriceInput: MarketPriceInput;
  MarketPriceProductInput: MarketPriceProductInput;
  MarketPriceVariantInput: MarketPriceVariantInput;
  ShippingProfileFormInput: ShippingProfileFormInput;
  ShippingZoneInput: ShippingZoneInput;
  ShipingCountryInput: ShipingCountryInput;
  ShippingRateInput: ShippingRateInput;
  UpdateTaxInput: UpdateTaxInput;
  TaxCurrenciesInput: TaxCurrenciesInput;
  ValidationResult: ValidationResult;
  CheckEmail: CheckEmail;
  DiscountCurrenciesInput: DiscountCurrenciesInput;
  DiscountResponse: DiscountResponse;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  getCurrencyPair?: Resolver<Maybe<ResolversTypes['GetCurrencyPairResponse']>, ParentType, ContextType, RequireFields<QueryGetCurrencyPairArgs, 'target'>>;
  getCustomerById?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType, RequireFields<QueryGetCustomerByIdArgs, 'customerId'>>;
  getCustomerOrdersCount?: Resolver<ResolversTypes['OrdersCount'], ParentType, ContextType, RequireFields<QueryGetCustomerOrdersCountArgs, 'customerId'>>;
  getDiscount?: Resolver<Maybe<ResolversTypes['Discount']>, ParentType, ContextType, RequireFields<QueryGetDiscountArgs, 'discountId'>>;
  getDiscountCurrencies?: Resolver<ResolversTypes['DiscountCurrenciesResponse'], ParentType, ContextType, RequireFields<QueryGetDiscountCurrenciesArgs, 'discountId'>>;
  getFulfillmentGroups?: Resolver<Maybe<ResolversTypes['OrderFulfillmentGroup']>, ParentType, ContextType>;
  getMarket?: Resolver<Maybe<ResolversTypes['Market']>, ParentType, ContextType, RequireFields<QueryGetMarketArgs, 'id'>>;
  getOrder?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType, RequireFields<QueryGetOrderArgs, 'orderId'>>;
  getProduct?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<QueryGetProductArgs, 'productId'>>;
  getShipping?: Resolver<Maybe<ResolversTypes['ShippingProfile']>, ParentType, ContextType>;
  getShippingCurrencies?: Resolver<ResolversTypes['ShippingCurrenciesResponse'], ParentType, ContextType>;
  getTax?: Resolver<Maybe<ResolversTypes['Tax']>, ParentType, ContextType, RequireFields<QueryGetTaxArgs, 'taxId'>>;
  getTaxCurrencies?: Resolver<ResolversTypes['TaxCurrenciesResponse'], ParentType, ContextType, RequireFields<QueryGetTaxCurrenciesArgs, 'taxId'>>;
  getTaxes?: Resolver<ResolversTypes['GetTaxesResponse'], ParentType, ContextType, Partial<QueryGetTaxesArgs>>;
  getTaxesForProductVariant?: Resolver<ResolversTypes['GetTaxesForProductVariantResponse'], ParentType, ContextType, RequireFields<QueryGetTaxesForProductVariantArgs, 'productId' | 'variantId'>>;
  getVariant?: Resolver<Maybe<ResolversTypes['Variant']>, ParentType, ContextType, RequireFields<QueryGetVariantArgs, 'productId' | 'variantId'>>;
};

export type GetCurrencyPairResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetCurrencyPairResponse'] = ResolversParentTypes['GetCurrencyPairResponse']> = {
  pair?: Resolver<Maybe<ResolversTypes['CurrencyPair']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyPairResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrencyPair'] = ResolversParentTypes['CurrencyPair']> = {
  baseCurrency?: Resolver<ResolversTypes['CurrencyEnum'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  targetCurrency?: Resolver<ResolversTypes['CurrencyEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Customer'] = ResolversParentTypes['Customer']> = {
  analytics?: Resolver<Maybe<ResolversTypes['Analytics']>, ParentType, ContextType>;
  billingAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastOrder?: Resolver<Maybe<ResolversTypes['LastOrder']>, ParentType, ContextType>;
  marketingOptInLevel?: Resolver<Maybe<ResolversTypes['MarketingLevelEnum']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shippingAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Analytics'] = ResolversParentTypes['Analytics']> = {
  averageOrderValue?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  ordersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalSpent?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price'] = ResolversParentTypes['Price']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['CurrencyEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = {
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  addressCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  divisionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LastOrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['LastOrder'] = ResolversParentTypes['LastOrder']> = {
  orderConfirmedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrdersCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrdersCount'] = ResolversParentTypes['OrdersCount']> = {
  ordersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountResolvers<ContextType = any, ParentType extends ResolversParentTypes['Discount'] = ResolversParentTypes['Discount']> = {
  currency?: Resolver<Maybe<ResolversTypes['CurrencyEnum']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rules?: Resolver<ResolversTypes['DiscountRules'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['DiscountStatusType'], ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['DiscountType'], ParentType, ContextType>;
  usage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountRulesResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountRules'] = ResolversParentTypes['DiscountRules']> = {
  endsAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  entitledCountryCodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  entitledProductVariantIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['DiscountProductVariantId']>>>, ParentType, ContextType>;
  prerequisiteCustomerIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  prerequisiteMinPurchaseAmount?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  prerequisiteMinQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prerequisiteProductVariantIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['DiscountProductVariantId']>>>, ParentType, ContextType>;
  prerequisiteToEntitlement?: Resolver<Maybe<ResolversTypes['DiscountPrerequisiteToEntitlement']>, ParentType, ContextType>;
  startsAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['DiscountRuleType']>, ParentType, ContextType>;
  usageLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  usageOncePerUser?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['DiscountValue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountProductVariantIdResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountProductVariantId'] = ResolversParentTypes['DiscountProductVariantId']> = {
  productId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  variantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountPrerequisiteToEntitlementResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountPrerequisiteToEntitlement'] = ResolversParentTypes['DiscountPrerequisiteToEntitlement']> = {
  entitledQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxNumberOfUses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prerequisitePurchaseAmount?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  prerequisiteQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountValue'] = ResolversParentTypes['DiscountValue']> = {
  __resolveType: TypeResolveFn<'DiscountPriceValue' | 'DiscountScalarValue', ParentType, ContextType>;
};

export type DiscountPriceValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountPriceValue'] = ResolversParentTypes['DiscountPriceValue']> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['CurrencyEnum']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['DiscountValueType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountScalarValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountScalarValue'] = ResolversParentTypes['DiscountScalarValue']> = {
  type?: Resolver<Maybe<ResolversTypes['DiscountValueType']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountCurrenciesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountCurrenciesResponse'] = ResolversParentTypes['DiscountCurrenciesResponse']> = {
  currencies?: Resolver<Array<ResolversTypes['Discount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderFulfillmentGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderFulfillmentGroup'] = ResolversParentTypes['OrderFulfillmentGroup']> = {
  fulfillmentGroupId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  fulfillmentStatus?: Resolver<ResolversTypes['FulfillmentStatusEnum'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productIds?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userSub?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketResolvers<ContextType = any, ParentType extends ResolversParentTypes['Market'] = ResolversParentTypes['Market']> = {
  countries?: Resolver<Array<ResolversTypes['Country']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Country'] = ResolversParentTypes['Country']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order'] = ResolversParentTypes['Order']> = {
  canceledAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cancelReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  charge?: Resolver<Maybe<ResolversTypes['Charge']>, ParentType, ContextType>;
  chargebackFees?: Resolver<Maybe<Array<Maybe<ResolversTypes['Price']>>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customAttributes?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Discount']>, ParentType, ContextType>;
  dispute?: Resolver<Maybe<ResolversTypes['Dispute']>, ParentType, ContextType>;
  exchangeRate?: Resolver<Maybe<ResolversTypes['ExchangeRate']>, ParentType, ContextType>;
  fee?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  finalSubTotalPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  fraudDetection?: Resolver<Maybe<ResolversTypes['FraudDetection']>, ParentType, ContextType>;
  fulfillments?: Resolver<Maybe<Array<Maybe<ResolversTypes['Fulfillment']>>>, ParentType, ContextType>;
  fulfillmentStatus?: Resolver<Maybe<ResolversTypes['FulfillmentStatusEnum']>, ParentType, ContextType>;
  hasActivityComments?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lineItemQuantities?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  lineItems?: Resolver<Maybe<Array<Maybe<ResolversTypes['LineItem']>>>, ParentType, ContextType>;
  lineItemsRefund?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  netPayment?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalSubTotalPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  paymentStatus?: Resolver<Maybe<ResolversTypes['PaymentStatusEnum']>, ParentType, ContextType>;
  refunds?: Resolver<Maybe<Array<Maybe<ResolversTypes['Refund']>>>, ParentType, ContextType>;
  requiresShipping?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  shippingMethod?: Resolver<Maybe<ResolversTypes['ShippingMethod']>, ParentType, ContextType>;
  shippingPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  shippingRefund?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['OrderStatusEnum']>, ParentType, ContextType>;
  taxes?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tax']>>>, ParentType, ContextType>;
  taxPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  totalChargebackFee?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  totalChargebacks?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  totalDiscountPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  totalPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  totalRefund?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usdPrices?: Resolver<Maybe<ResolversTypes['Prices']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Charge'] = ResolversParentTypes['Charge']> = {
  amount?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  amountRefunded?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isDisputed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isLivemode?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPaid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isRefunded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  paymentMethod?: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType>;
  receiptNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  risk?: Resolver<Maybe<ResolversTypes['Risk']>, ParentType, ContextType>;
  service?: Resolver<Maybe<ResolversTypes['PaymentServiceEnum']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentMethod'] = ResolversParentTypes['PaymentMethod']> = {
  card?: Resolver<ResolversTypes['Card'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CardResolvers<ContextType = any, ParentType extends ResolversParentTypes['Card'] = ResolversParentTypes['Card']> = {
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expMonth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  expYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  last4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  network?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RiskResolvers<ContextType = any, ParentType extends ResolversParentTypes['Risk'] = ResolversParentTypes['Risk']> = {
  level?: Resolver<Maybe<ResolversTypes['RiskLevelEnum']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['RiskReasonEnum']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['RiskStatusEnum']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['RiskTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface AwsjsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSJSON'], any> {
  name: 'AWSJSON';
}

export type DisputeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Dispute'] = ResolversParentTypes['Dispute']> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chargeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['CurrencyEnum']>, ParentType, ContextType>;
  evidenceDetails?: Resolver<Maybe<ResolversTypes['DisputeEvidenceDetails']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isChargeRefundable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DisputeStatusEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DisputeEvidenceDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DisputeEvidenceDetails'] = ResolversParentTypes['DisputeEvidenceDetails']> = {
  dueBy?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasEvidence?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  pastDue?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  submissionCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExchangeRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExchangeRate'] = ResolversParentTypes['ExchangeRate']> = {
  base?: Resolver<Maybe<ResolversTypes['CurrencyEnum']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  target?: Resolver<Maybe<ResolversTypes['CurrencyEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FraudDetectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FraudDetection'] = ResolversParentTypes['FraudDetection']> = {
  chargeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdFraudAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fraudType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FulfillmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Fulfillment'] = ResolversParentTypes['Fulfillment']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineItems?: Resolver<Maybe<Array<Maybe<ResolversTypes['FulfillmentItem']>>>, ParentType, ContextType>;
  shippingAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  tracker?: Resolver<Maybe<ResolversTypes['Tracker']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FulfillmentItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['FulfillmentItem'] = ResolversParentTypes['FulfillmentItem']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrackerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tracker'] = ResolversParentTypes['Tracker']> = {
  carrier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trackingNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['LineItem'] = ResolversParentTypes['LineItem']> = {
  customAttributes?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  finalPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  fulfillmentLineItems?: Resolver<Maybe<Array<Maybe<ResolversTypes['FulfillmentLineItem']>>>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineItemId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  productId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxes?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tax']>>>, ParentType, ContextType>;
  totalPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  variantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  variantTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  variantType?: Resolver<Maybe<ResolversTypes['VariantTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FulfillmentLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['FulfillmentLineItem'] = ResolversParentTypes['FulfillmentLineItem']> = {
  lineItemId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tax'] = ResolversParentTypes['Tax']> = {
  collectionType?: Resolver<ResolversTypes['TaxCollectionEnum'], ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['CurrencyEnum']>, ParentType, ContextType>;
  excludedProductVariants?: Resolver<Array<ResolversTypes['TaxProductVariant']>, ParentType, ContextType>;
  flatRate?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  productVariants?: Resolver<Array<ResolversTypes['TaxProductVariant']>, ParentType, ContextType>;
  taxId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['TaxTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxProductVariantResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxProductVariant'] = ResolversParentTypes['TaxProductVariant']> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  taxId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  variant?: Resolver<ResolversTypes['Variant'], ParentType, ContextType>;
  variantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  optionLabels?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  productId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  variants?: Resolver<Array<ResolversTypes['Variant']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VariantResolvers<ContextType = any, ParentType extends ResolversParentTypes['Variant'] = ResolversParentTypes['Variant']> = {
  chargeTax?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  comparePrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disableAutomaticDiscount?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  discountedPackagePrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  discountedPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  discountedUpsellPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  internalPackagePrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  internalPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  internalUpsellPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  isOversellable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  option1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  option2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  option3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  optionLabels?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  packagePrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  productId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trackQuantity?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  upsellPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  variantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  variantType?: Resolver<Maybe<ResolversTypes['VariantTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundResolvers<ContextType = any, ParentType extends ResolversParentTypes['Refund'] = ResolversParentTypes['Refund']> = {
  amount?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  chargeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdRefundAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineItems?: Resolver<Maybe<Array<Maybe<ResolversTypes['RefundLineItem']>>>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receiptNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundLineItem'] = ResolversParentTypes['RefundLineItem']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingMethod'] = ResolversParentTypes['ShippingMethod']> = {
  zone?: Resolver<Maybe<ResolversTypes['ShippingMethodZone']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingMethodZoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingMethodZone'] = ResolversParentTypes['ShippingMethodZone']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['ShippingRate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingRate'] = ResolversParentTypes['ShippingRate']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Prices'] = ResolversParentTypes['Prices']> = {
  fee?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  finalSubTotalPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  lineItemsRefund?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  netPayment?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  originalSubTotalPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  shippingPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  shippingRefund?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  taxPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  taxRefund?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  totalChargebackFee?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  totalChargebacks?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  totalDiscountPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  totalPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  totalRefund?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingProfile'] = ResolversParentTypes['ShippingProfile']> = {
  currency?: Resolver<ResolversTypes['CurrencyEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  zones?: Resolver<Array<ResolversTypes['ShippingZone']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingZoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingZone'] = ResolversParentTypes['ShippingZone']> = {
  countries?: Resolver<Array<ResolversTypes['ShipingCountry']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rates?: Resolver<Array<ResolversTypes['ShippingRate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShipingCountryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShipingCountry'] = ResolversParentTypes['ShipingCountry']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  restOfWorld?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingCurrenciesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingCurrenciesResponse'] = ResolversParentTypes['ShippingCurrenciesResponse']> = {
  profiles?: Resolver<Array<ResolversTypes['ShippingProfile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxCurrenciesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxCurrenciesResponse'] = ResolversParentTypes['TaxCurrenciesResponse']> = {
  currencies?: Resolver<Array<ResolversTypes['Price']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetTaxesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetTaxesResponse'] = ResolversParentTypes['GetTaxesResponse']> = {
  hasMoreResults?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  taxes?: Resolver<Array<ResolversTypes['Tax']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetTaxesForProductVariantResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetTaxesForProductVariantResponse'] = ResolversParentTypes['GetTaxesForProductVariantResponse']> = {
  taxes?: Resolver<Array<ResolversTypes['Tax']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  archiveOrder?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<MutationArchiveOrderArgs, 'orderId'>>;
  cancelFulfillment?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<MutationCancelFulfillmentArgs, 'fulfillmentId' | 'orderId'>>;
  cancelOrder?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<MutationCancelOrderArgs, 'orderId'>>;
  createCustomer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType, RequireFields<MutationCreateCustomerArgs, 'customer'>>;
  createDiscount?: Resolver<Maybe<ResolversTypes['Discount']>, ParentType, ContextType, RequireFields<MutationCreateDiscountArgs, 'input'>>;
  createExcludedTaxProductVariant?: Resolver<ResolversTypes['CreateExcludedTaxProductVariantResult'], ParentType, ContextType, RequireFields<MutationCreateExcludedTaxProductVariantArgs, 'input'>>;
  createMarket?: Resolver<ResolversTypes['Market'], ParentType, ContextType, RequireFields<MutationCreateMarketArgs, 'market'>>;
  createOrder?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<MutationCreateOrderArgs, 'order'>>;
  createOrderFulfillmentGroup?: Resolver<Maybe<ResolversTypes['OrderFulfillmentGroup']>, ParentType, ContextType, RequireFields<MutationCreateOrderFulfillmentGroupArgs, 'orderFulfillment'>>;
  createPresignedUrl?: Resolver<ResolversTypes['Assets'], ParentType, ContextType, RequireFields<MutationCreatePresignedUrlArgs, 'assets' | 'prefixKey'>>;
  createProduct?: Resolver<ResolversTypes['Product'], ParentType, ContextType, RequireFields<MutationCreateProductArgs, 'product'>>;
  createTax?: Resolver<ResolversTypes['Tax'], ParentType, ContextType, RequireFields<MutationCreateTaxArgs, 'input'>>;
  createTaxProductVariant?: Resolver<Maybe<ResolversTypes['TaxProductVariant']>, ParentType, ContextType, RequireFields<MutationCreateTaxProductVariantArgs, 'input' | 'taxId'>>;
  createVariant?: Resolver<ResolversTypes['Variant'], ParentType, ContextType, RequireFields<MutationCreateVariantArgs, 'productId' | 'variant'>>;
  deleteDiscount?: Resolver<Maybe<ResolversTypes['DiscountSuccess']>, ParentType, ContextType, RequireFields<MutationDeleteDiscountArgs, 'discountId'>>;
  deleteDiscounts?: Resolver<Maybe<ResolversTypes['DiscountSuccess']>, ParentType, ContextType, RequireFields<MutationDeleteDiscountsArgs, 'discountIds'>>;
  deleteExcludedTaxProductVariant?: Resolver<ResolversTypes['DeleteExcludedTaxProductVariantResult'], ParentType, ContextType, RequireFields<MutationDeleteExcludedTaxProductVariantArgs, 'input'>>;
  deleteLineItemCustomAttributeKey?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<MutationDeleteLineItemCustomAttributeKeyArgs, 'attributeKey' | 'lineItemId' | 'orderId'>>;
  deleteMarket?: Resolver<ResolversTypes['DeleteSuccess'], ParentType, ContextType, RequireFields<MutationDeleteMarketArgs, 'id'>>;
  deleteOrderFulfillmentGroup?: Resolver<ResolversTypes['DeleteSuccess'], ParentType, ContextType, RequireFields<MutationDeleteOrderFulfillmentGroupArgs, 'orderFulfillmentGroupId'>>;
  deleteProduct?: Resolver<ResolversTypes['DeleteSuccess'], ParentType, ContextType, RequireFields<MutationDeleteProductArgs, 'productId'>>;
  deleteTax?: Resolver<ResolversTypes['DeleteSuccess'], ParentType, ContextType, RequireFields<MutationDeleteTaxArgs, 'taxId'>>;
  deleteTaxProductVariant?: Resolver<Maybe<ResolversTypes['TaxProductVariant']>, ParentType, ContextType, RequireFields<MutationDeleteTaxProductVariantArgs, 'input'>>;
  deleteVariant?: Resolver<ResolversTypes['Variant'], ParentType, ContextType, RequireFields<MutationDeleteVariantArgs, 'productId' | 'variantId'>>;
  disableDiscounts?: Resolver<Maybe<ResolversTypes['DiscountSuccess']>, ParentType, ContextType, RequireFields<MutationDisableDiscountsArgs, 'discountIds'>>;
  enableDiscounts?: Resolver<Maybe<ResolversTypes['DiscountSuccess']>, ParentType, ContextType, RequireFields<MutationEnableDiscountsArgs, 'discountIds'>>;
  exportOrders?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationExportOrdersArgs, 'input'>>;
  fulfillLineItems?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<MutationFulfillLineItemsArgs, 'fulfillment' | 'orderId'>>;
  refundOrder?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<MutationRefundOrderArgs, 'orderId' | 'refund'>>;
  resetMarketProductPrice?: Resolver<ResolversTypes['MarketSuccess'], ParentType, ContextType, RequireFields<MutationResetMarketProductPriceArgs, 'id' | 'productId'>>;
  restockLineItems?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<MutationRestockLineItemsArgs, 'orderId' | 'restocks'>>;
  setCustomerBillingAddress?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<MutationSetCustomerBillingAddressArgs, 'billingAddress' | 'orderId'>>;
  setCustomerShippingAddress?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<MutationSetCustomerShippingAddressArgs, 'orderId' | 'shippingAddress'>>;
  setDefaultCurrencyMarketProductPrices?: Resolver<ResolversTypes['MarketSuccess'], ParentType, ContextType, RequireFields<MutationSetDefaultCurrencyMarketProductPricesArgs, 'id' | 'input'>>;
  setMarketCurrencyMarketProductPrices?: Resolver<ResolversTypes['MarketSuccess'], ParentType, ContextType, RequireFields<MutationSetMarketCurrencyMarketProductPricesArgs, 'id' | 'input'>>;
  setTrackToFulfillment?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<MutationSetTrackToFulfillmentArgs, 'fulfillmentId' | 'orderId' | 'tracker'>>;
  syncDiscountCurrencies?: Resolver<Array<ResolversTypes['Discount']>, ParentType, ContextType, RequireFields<MutationSyncDiscountCurrenciesArgs, 'discountId'>>;
  syncMarketProductPrice?: Resolver<ResolversTypes['MarketSuccess'], ParentType, ContextType, RequireFields<MutationSyncMarketProductPriceArgs, 'productId'>>;
  syncShippingCurrencies?: Resolver<Array<ResolversTypes['ShippingProfile']>, ParentType, ContextType, RequireFields<MutationSyncShippingCurrenciesArgs, 'shippingId'>>;
  syncTaxCurrencies?: Resolver<Array<ResolversTypes['Tax']>, ParentType, ContextType, RequireFields<MutationSyncTaxCurrenciesArgs, 'taxId'>>;
  updateCustomer?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<MutationUpdateCustomerArgs, 'customer' | 'orderId'>>;
  updateCustomerInfo?: Resolver<ResolversTypes['Customer'], ParentType, ContextType, RequireFields<MutationUpdateCustomerInfoArgs, 'customer' | 'customerId'>>;
  updateDiscount?: Resolver<Maybe<ResolversTypes['Discount']>, ParentType, ContextType, RequireFields<MutationUpdateDiscountArgs, 'discountId' | 'input'>>;
  updateDiscountCurrencies?: Resolver<ResolversTypes['DiscountCurrenciesResponse'], ParentType, ContextType, RequireFields<MutationUpdateDiscountCurrenciesArgs, 'discountId' | 'input'>>;
  updateMarket?: Resolver<ResolversTypes['Market'], ParentType, ContextType, RequireFields<MutationUpdateMarketArgs, 'id' | 'market'>>;
  updateMarketCountries?: Resolver<ResolversTypes['MarketSuccess'], ParentType, ContextType, RequireFields<MutationUpdateMarketCountriesArgs, 'id' | 'input'>>;
  updateMarketPrice?: Resolver<ResolversTypes['MarketSuccess'], ParentType, ContextType, RequireFields<MutationUpdateMarketPriceArgs, 'id' | 'input'>>;
  updateOrderFulfillmentGroup?: Resolver<Maybe<ResolversTypes['OrderFulfillmentGroup']>, ParentType, ContextType, RequireFields<MutationUpdateOrderFulfillmentGroupArgs, 'orderFulfillment' | 'orderFulfillmentGroupId'>>;
  updateProduct?: Resolver<ResolversTypes['Product'], ParentType, ContextType, RequireFields<MutationUpdateProductArgs, 'product' | 'productId'>>;
  updateShipping?: Resolver<ResolversTypes['ShippingProfile'], ParentType, ContextType, RequireFields<MutationUpdateShippingArgs, 'input'>>;
  updateShippingCurrencies?: Resolver<ResolversTypes['ShippingCurrenciesResponse'], ParentType, ContextType, RequireFields<MutationUpdateShippingCurrenciesArgs, 'input' | 'shippingId'>>;
  updateTax?: Resolver<ResolversTypes['Tax'], ParentType, ContextType, RequireFields<MutationUpdateTaxArgs, 'input'>>;
  updateTaxCurrencies?: Resolver<ResolversTypes['TaxCurrenciesResponse'], ParentType, ContextType, RequireFields<MutationUpdateTaxCurrenciesArgs, 'input' | 'taxId'>>;
  updateVariant?: Resolver<ResolversTypes['Variant'], ParentType, ContextType, RequireFields<MutationUpdateVariantArgs, 'productId' | 'variant' | 'variantId'>>;
  validateEmail?: Resolver<Maybe<ResolversTypes['ValidationResult']>, ParentType, ContextType, RequireFields<MutationValidateEmailArgs, 'email'>>;
};

export type CreateExcludedTaxProductVariantResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateExcludedTaxProductVariantResult'] = ResolversParentTypes['CreateExcludedTaxProductVariantResult']> = {
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  taxId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  variantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssetsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Assets'] = ResolversParentTypes['Assets']> = {
  bucketName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountSuccess'] = ResolversParentTypes['DiscountSuccess']> = {
  successful?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteExcludedTaxProductVariantResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteExcludedTaxProductVariantResult'] = ResolversParentTypes['DeleteExcludedTaxProductVariantResult']> = {
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  taxID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  variantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteSuccess'] = ResolversParentTypes['DeleteSuccess']> = {
  isDeleteSuccess?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketSuccess'] = ResolversParentTypes['MarketSuccess']> = {
  successful?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ValidationResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ValidationResult'] = ResolversParentTypes['ValidationResult']> = {
  isValid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountResponse'] = ResolversParentTypes['DiscountResponse']> = {
  discount?: Resolver<Maybe<ResolversTypes['Discount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Query?: QueryResolvers<ContextType>;
  GetCurrencyPairResponse?: GetCurrencyPairResponseResolvers<ContextType>;
  CurrencyPair?: CurrencyPairResolvers<ContextType>;
  Customer?: CustomerResolvers<ContextType>;
  Analytics?: AnalyticsResolvers<ContextType>;
  Price?: PriceResolvers<ContextType>;
  Address?: AddressResolvers<ContextType>;
  LastOrder?: LastOrderResolvers<ContextType>;
  OrdersCount?: OrdersCountResolvers<ContextType>;
  Discount?: DiscountResolvers<ContextType>;
  DiscountRules?: DiscountRulesResolvers<ContextType>;
  DiscountProductVariantId?: DiscountProductVariantIdResolvers<ContextType>;
  DiscountPrerequisiteToEntitlement?: DiscountPrerequisiteToEntitlementResolvers<ContextType>;
  DiscountValue?: DiscountValueResolvers<ContextType>;
  DiscountPriceValue?: DiscountPriceValueResolvers<ContextType>;
  DiscountScalarValue?: DiscountScalarValueResolvers<ContextType>;
  DiscountCurrenciesResponse?: DiscountCurrenciesResponseResolvers<ContextType>;
  OrderFulfillmentGroup?: OrderFulfillmentGroupResolvers<ContextType>;
  Market?: MarketResolvers<ContextType>;
  Country?: CountryResolvers<ContextType>;
  Order?: OrderResolvers<ContextType>;
  Charge?: ChargeResolvers<ContextType>;
  PaymentMethod?: PaymentMethodResolvers<ContextType>;
  Card?: CardResolvers<ContextType>;
  Risk?: RiskResolvers<ContextType>;
  AWSJSON?: GraphQLScalarType;
  Dispute?: DisputeResolvers<ContextType>;
  DisputeEvidenceDetails?: DisputeEvidenceDetailsResolvers<ContextType>;
  ExchangeRate?: ExchangeRateResolvers<ContextType>;
  FraudDetection?: FraudDetectionResolvers<ContextType>;
  Fulfillment?: FulfillmentResolvers<ContextType>;
  FulfillmentItem?: FulfillmentItemResolvers<ContextType>;
  Tracker?: TrackerResolvers<ContextType>;
  LineItem?: LineItemResolvers<ContextType>;
  FulfillmentLineItem?: FulfillmentLineItemResolvers<ContextType>;
  Tax?: TaxResolvers<ContextType>;
  TaxProductVariant?: TaxProductVariantResolvers<ContextType>;
  Product?: ProductResolvers<ContextType>;
  Variant?: VariantResolvers<ContextType>;
  Refund?: RefundResolvers<ContextType>;
  RefundLineItem?: RefundLineItemResolvers<ContextType>;
  ShippingMethod?: ShippingMethodResolvers<ContextType>;
  ShippingMethodZone?: ShippingMethodZoneResolvers<ContextType>;
  ShippingRate?: ShippingRateResolvers<ContextType>;
  Prices?: PricesResolvers<ContextType>;
  ShippingProfile?: ShippingProfileResolvers<ContextType>;
  ShippingZone?: ShippingZoneResolvers<ContextType>;
  ShipingCountry?: ShipingCountryResolvers<ContextType>;
  ShippingCurrenciesResponse?: ShippingCurrenciesResponseResolvers<ContextType>;
  TaxCurrenciesResponse?: TaxCurrenciesResponseResolvers<ContextType>;
  GetTaxesResponse?: GetTaxesResponseResolvers<ContextType>;
  GetTaxesForProductVariantResponse?: GetTaxesForProductVariantResponseResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  CreateExcludedTaxProductVariantResult?: CreateExcludedTaxProductVariantResultResolvers<ContextType>;
  Assets?: AssetsResolvers<ContextType>;
  DiscountSuccess?: DiscountSuccessResolvers<ContextType>;
  DeleteExcludedTaxProductVariantResult?: DeleteExcludedTaxProductVariantResultResolvers<ContextType>;
  DeleteSuccess?: DeleteSuccessResolvers<ContextType>;
  MarketSuccess?: MarketSuccessResolvers<ContextType>;
  ValidationResult?: ValidationResultResolvers<ContextType>;
  DiscountResponse?: DiscountResponseResolvers<ContextType>;
};

