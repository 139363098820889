import apolloClient from '@/services/apollo'
import { GET_PRODUCT, GET_VARIANT } from '@/graphql/query/product'
import {
  Maybe,
  MutationDeleteProductArgs,
  MutationUpdateVariantArgs,
  MutationCreateVariantArgs,
  Product,
  QueryGetProductArgs,
  QueryGetVariantArgs,
  Variant,
  MutationCreateProductArgs,
  ProductInput,
  VariantInput,
  MutationDeleteVariantArgs,
  MutationUpdateProductArgs,
} from '../../../shared/types/types'
import {
  DELETE_PRODUCT,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  UPDATE_VARIANT,
  CREATE_VARIANT,
  DELETE_VARIANT,
} from '@/graphql/mutation/product'

interface GetProductResult {
  getProduct: Maybe<Product>
}

interface DeleteProductResult {
  deleteProduct: string
}

interface GetVariantResult {
  getVariant: Maybe<Variant>
}

interface CreateProductResult {
  createProduct: Maybe<Product>
}
interface UpdateVariantResult {
  updateVariant: Maybe<Variant>
}
interface CreateVariantResult {
  createVariant: Maybe<Variant>
}

interface DeleteVariantResult {
  deleteVariant: Maybe<Variant>
}

interface UpdateProductResult {
  updateProduct: Maybe<Product>
}
/**
 * Get product
 *
 * @param productId Product ID
 */
export const getProduct = async (
  productId: string
): Promise<Maybe<Product>> => {
  const { data } = await apolloClient.query<
    GetProductResult,
    QueryGetProductArgs
  >({
    query: GET_PRODUCT,
    variables: {
      productId,
    },
    fetchPolicy: 'network-only',
  })

  return data.getProduct
}

/**
 * Delete a product
 *
 * @param productId Product ID
 */
export const deleteProduct = async (
  productId: string
): Promise<string | undefined> => {
  const { data } = await apolloClient.mutate<
    DeleteProductResult,
    MutationDeleteProductArgs
  >({
    mutation: DELETE_PRODUCT,
    variables: {
      productId,
    },
  })

  return data?.deleteProduct
}

/**
 * Get a Variant
 *
 * @param productId Product ID
 * @param variantId Variant ID
 */
export const getVariant = async (
  productId: string,
  variantId: string
): Promise<Maybe<Variant>> => {
  const { data } = await apolloClient.query<
    GetVariantResult,
    QueryGetVariantArgs
  >({
    query: GET_VARIANT,
    fetchPolicy: 'network-only',
    variables: {
      productId,
      variantId,
    },
  })

  return data.getVariant
}

/**
 * Create a product
 *
 * @param product to be created
 */
export const createProduct = async (
  product: ProductInput
): Promise<Maybe<Product> | undefined> => {
  const { data } = await apolloClient.mutate<
    CreateProductResult,
    MutationCreateProductArgs
  >({
    mutation: CREATE_PRODUCT,
    variables: {
      product: product,
    },
  })

  return data?.createProduct
}

/* Update the Variant Details
 *
 * @param productId Product ID
 * @param variantId Variant ID
 * @param variant Variant <Variant Input>
 */
export const updateVariant = async (
  productId: string,
  variantId: string,
  variant: VariantInput
): Promise<Maybe<Variant> | undefined> => {
  const { data } = await apolloClient.mutate<
    UpdateVariantResult,
    MutationUpdateVariantArgs
  >({
    mutation: UPDATE_VARIANT,
    variables: {
      productId,
      variantId,
      variant,
    },
  })

  return data?.updateVariant
}

/**
 * Create a Variant
 *
 * @param productId Product ID
 * @param variantId Variant ID
 * @param variant Variant <Variant Input>
 */
export const createVariant = async (
  productId: string,
  variant: VariantInput
): Promise<Maybe<Variant> | undefined> => {
  const { data } = await apolloClient.mutate<
    CreateVariantResult,
    MutationCreateVariantArgs
  >({
    mutation: CREATE_VARIANT,
    variables: {
      productId,
      variant,
    },
  })

  return data?.createVariant
}

/**
 * Delete a Variant
 *
 * @param productId Product ID
 * @param variantId Variant ID
 */
export const deleteVariant = async (
  productId: string,
  variantId: string
): Promise<Maybe<Variant> | undefined> => {
  const { data } = await apolloClient.mutate<
    DeleteVariantResult,
    MutationDeleteVariantArgs
  >({
    mutation: DELETE_VARIANT,
    variables: {
      productId,
      variantId,
    },
  })

  return data?.deleteVariant
}

/**
 * Update a product
 *
 * @param product to be created
 */
export const updateProduct = async (
  productId: string,
  product: ProductInput
): Promise<Maybe<Product> | undefined> => {
  const { data } = await apolloClient.mutate<
    UpdateProductResult,
    MutationUpdateProductArgs
  >({
    mutation: UPDATE_PRODUCT,
    variables: {
      product: product,
      productId: productId,
    },
  })

  return data?.updateProduct
}
